var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "v-data-table--default tbody-tr-cursor-pointer",
    attrs: {
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "no-data-text": "No question has been submitted"
    },
    on: {
      "click:row": function (any, _ref) {
        var item = _ref.item;
        return _vm.showBoard(item);
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.meta.isReplied`,
      fn: function (_ref3) {
        var value = _ref3.value;
        return [(value = true) ? [_c('span', {
          staticClass: "font-size-14 font-weight-light secondary--text"
        }, [_vm._v("Completed")])] : [_c('span', {
          staticClass: "font-size-14 font-weight-light grey-a--text"
        }, [_vm._v("Waiting")])]];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "board-bottom"
        }, [_c('v-row', {
          attrs: {
            "justify": "end"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "w-120px",
          attrs: {
            "small": "",
            "exact": "",
            "color": "secondary"
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push(`${_vm.$route.path}/write`);
            }
          }
        }, [_vm._v("Inquiry")])], 1)], 1), _c('v-row', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false"
          }],
          attrs: {
            "justify": "end"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "w-120px",
          attrs: {
            "small": "",
            "outlined": "",
            "exact": "",
            "color": "grey-d"
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('loadmore');
            }
          }
        }, [_vm._v("List")])], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "w-120px",
          attrs: {
            "small": "",
            "exact": "",
            "color": "secondary"
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push('?mode=input');
            }
          }
        }, [_vm._v("Inquiry")])], 1)], 1), _c('pagination-component', _vm._b({
          attrs: {
            "value": _vm.page
          },
          on: {
            "change": _vm.changePage
          }
        }, 'pagination-component', {
          count: _vm.count
        }, false))], 1)];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }